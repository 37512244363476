/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react'

type SectionVideoProps = {
  handleScrollDown: boolean
}

const SectionVideo = ({ handleScrollDown }: SectionVideoProps) => {
  const [handleVideo, setHandleVideo] = useState(false)
  const element = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (handleScrollDown) {
      element?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [handleScrollDown])

  return (
    <div className="dW_video" ref={element}>
      <div
        className="dW_v-titre dW_generic__animOnScroll dW_generic__toAnimOnScroll dW_generic__prepareAnimOnScroll dW_generic__startAnimOnScroll"
        data-paramsOnScroll="{}"
      >
        <h2>
          <span className="dW_vt-content">
            <span>A ECO-CONCEPÇÃO NA DECATHLON</span>
          </span>
        </h2>
      </div>
      <div
        className="dW_v-player dW_generic__animOnScroll dW_generic__toAnimOnScroll dW_generic__prepareAnimOnScroll dW_generic__startAnimOnScroll"
        data-paramsOnScroll="{}"
      >
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/lp-e-c-2020-1ptrans-21.gif"
          width="1600"
          height="800"
          alt=""
          title=""
          id="video-player"
          onClick={() => setHandleVideo(!handleVideo)}
        />
        {handleVideo && (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ZKOulGPQxCM?&amp;autoplay=1&amp;rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="produtos eco video"
            id="video-produtos-eco"
          />
        )}
      </div>
    </div>
  )
}

export default SectionVideo
