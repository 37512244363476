import { useEffect, useRef, useState } from 'react'

const data = [
  {
    title: 'O algodão orgânico',
    description:
      'O algodão da agricultura orgânica é cultivado sem o uso de produtos químicos. Os métodos de cultura orgânica foram criados     especificamente para diminuir o impacto sobre o meio ambiente. Ele preserva a biodiversidade, a fertilidade dos solos e diminui o risco de poluição dos lençóis freáticos.',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/lp-ec-2020-process-cotion-bio.jpg',
    dataIndex: '1',
  },
  {
    title: 'O dope-dyed',
    description:
      'O tingimento de tecido é um processo que requer muita água, alto consumo de energia e que gera desperdício nos tanques de tingimento. Para reduzir esse impacto ambiental, escolhemos a coloração em massa que integra os pigmentos de cor na fibra ao fabricar o fio.',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/lp-ec-2020-process-dope-dye.jpg',
    dataIndex: '2',
  },
  {
    title: 'O poliéster reciclado',
    description:
      'Reciclando garrafas plásticas ou tecidos para produzir nosso poliéster, diminuímos o uso de recursos provenientes do petróleo, preservando as qualidades de respirabilidade do produto durante a sua prática.',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/lp-ec-2020-process-polyester-recycle.jpg',
    dataIndex: '3',
  },
  {
    title: 'A tecnologia Biton',
    description:
      'Para reduzir o impacto de utilização da água e seus resíduos quando ela é usada, utilizamos apenas um fio tingido em massa a cada dois e, assim, reduzimos nosso consumo.',
    image:
      'https://decathlonpro.vteximg.com.br/arquivos/lp-ec-2020-process-biton.jpg',
    dataIndex: '4',
  },
]

const delay = 8000

const CarouselCustom = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setCurrentSlide((prevIndex) =>
          prevIndex === data.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    )

    return () => {
      resetTimeout()
    }
  }, [currentSlide])

  const nextSlide = () => {
    setCurrentSlide(currentSlide === data.length - 1 ? 0 : currentSlide + 1)
  }

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? data.length - 1 : currentSlide - 1)
  }

  return (
    <div className="carousel-custom">
      {data.map((item, index) => (
        <div
          className={index === currentSlide ? 'slide current' : 'slide'}
          key={index}
        >
          <img src={item.image} alt="carousel" />
        </div>
      ))}
      <div className="content-wrapper">
        <div className="prev-next">
          <button className="prev" onClick={() => prevSlide()} />
          <button className="next" onClick={() => nextSlide()} />
        </div>
        {data.map((item, index) => (
          <div
            className={index === currentSlide ? 'slide current' : 'slide'}
            key={index}
          >
            <h4>{item.title}</h4>
            <p className="description-carousel">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CarouselCustom
