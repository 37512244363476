import CarouselCustom from './CarouselCustom'

const SectionDetails = () => {
  return (
    <div className="dW_details">
      <div
        className="dW_d-intro dW_generic__animOnScroll dW_generic__toAnimOnScroll dW_generic__toEventOnScroll dW_generic__prepareAnimOnScroll dW_generic__startAnimOnScroll dW_generic__eventOnScroll"
        data-paramsOnScroll="{}"
        data-parallax-min="130"
        data-parallax-max="-130"
        id="details"
      >
        <div className="dW_di-texte">
          <h2>
            <span className="dW_dit-content">
              <span>MAS O QUE </span>
            </span>
            <br />
            <span className="dW_dit-content">
              <span>ISSO SIGNIFICA?</span>
            </span>
          </h2>
          <p>
            As abordagens de eco-concepção podem ser aplicadas em diferentes
            parâmetros do produto que visam sempre limitar seu impacto. Para
            entender melhor, veja aqui alguns exemplos de abordagens de
            eco-concepção:
          </p>
        </div>
      </div>
      <CarouselCustom />
      <div
        className="dW_d-produits dW_generic__link dW_generic__toEventOnScroll dW_generic__hover-out dW_generic__eventOnScroll"
        data-href="#"
        data-target="_blank"
        data-paramsOnScroll="{}"
        data-parallax-min="110"
        data-parallax-max="-110"
      >
        <div
          className="dW_dp-visuels dW_generic__animOnScroll"
          data-paramsOnScroll="{}"
        >
          <ul>
            <li className="dW_generic__open" data-index="1">
              {/* <vtex:contentPlaceHolder id="imagem-legging-yoga" /> */}
            </li>
            <li className="dW_generic__close" data-index="2">
              {/* <vtex:contentPlaceHolder id="imagem-surpantalon" /> */}
            </li>
            <li className="dW_generic__close" data-index="3">
              {/* <vtex:contentPlaceHolder id="imagem-tshirt-running" /> */}
            </li>
            <li className="dW_generic__close" data-index="4">
              {/* <vtex:contentPlaceHolder id="imagem-sac-couchage" /> */}
            </li>
          </ul>
        </div>
        <div
          className="dW_dp-intitules dW_generic__animOnScroll"
          data-paramsOnScroll="{}"
        >
          <ul>
            <li className="dW_generic__open" data-index="1">
              <h3>
                {/* <vtex:contentPlaceHolder id="texto-legging-yoga" /> */}
              </h3>
            </li>
            <li className="dW_generic__close" data-index="2">
              <h3>
                {/* <vtex:contentPlaceHolder id="texto-surpantalon" /> */}
              </h3>
            </li>
            <li className="dW_generic__close" data-index="3">
              <h3>
                {/* <vtex:contentPlaceHolder id="texto-tshirt-running" /> */}
              </h3>
            </li>
            <li className="dW_generic__close" data-index="4">
              <h3>
                {/* <vtex:contentPlaceHolder id="texto-sac-couchage" /> */}
              </h3>
            </li>
          </ul>
        </div>
        <div
          className="dW_dp-cta dW_generic__animOnScroll"
          data-paramsOnScroll="{}"
        />
      </div>
      <div
        className="dW_d-avenir dW_generic__toEventOnScroll dW_generic__eventOnScroll"
        data-paramsOnScroll="{}"
        data-parallax-min="40"
        data-parallax-max="-40"
      >
        <div
          className="dW_da-textes dW_generic__animOnScroll dW_generic__toAnimOnScroll dW_generic__prepareAnimOnScroll dW_generic__startAnimOnScroll"
          data-paramsOnScroll="{}"
        >
          <h2>
            <span className="dW_dat-content">
              <span>E NÃO PARAMOS AÍ!</span>
            </span>
          </h2>
          <p>
            Queremos tornar os prazeres e os benefícios da prática do esporte
            acessíveis por mais tempo ao maior número de pessoas. É por isso que
            a DECATHLON está hoje empenhada na eco-concepção de seus produtos.
            Hoje, já temos produtos feitos de forma ecológica disponíveis em
            nossas lojas. Ainda assim, eles representam apenas uma pequena
            parcela de nossa oferta atual. Compreendendo os desafios climáticos
            e o esgotamento de recursos, o objetivo das equipes DECATHLON é
            aumentar o número de novos produtos fabricados com uma abordagem de
            eco-concepção de forma mais significativa nos próximos anos e assim,
            reduzir nosso impacto sobre o meio ambiente.
          </p>
          <p>
            Conscientes de que ainda há muito trabalho a fazer para reduzir
            nosso impacto ambiental, estamos determinados a continuar agindo a
            favor da mudança. Para conhecer todos os nossos compromissos com o
            desenvolvimento sustentável, visite nosso website:
          </p>
        </div>
        <div
          className="dW_da-cta dW_generic__animOnScroll dW_generic__toAnimOnScroll dW_generic__prepareAnimOnScroll dW_generic__startAnimOnScroll"
          data-paramsOnScroll="{}"
        >
          <p>
            <a
              href="http://sustainability.decathlon.com/"
              target="_blank"
              rel="noreferrer"
              title="Saiba mais"
              className="button-cta"
            >
              Saiba mais
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SectionDetails
