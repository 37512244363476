import HoverComponent from './HoverComponent'

const Intro = () => {
  return (
    <div className="dW_intro">
      <div
        className="dW_i-logo dW_generic__animOnScroll"
        data-paramsOnScroll="{}"
      >
        <img
          className="img-loader logo-position-rotate img-loader-rotate"
          src="https://decathlonpro.vteximg.com.br/arquivos/lp-eco-concepcao-2020-loader-1.png"
          alt=""
          width="270"
          height="270"
        />
        <img
          className="img-loader logo-position-static"
          src="https://decathlonpro.vteximg.com.br/arquivos/lp-eco-concepcao-2020-loader-2.png"
          alt=""
          width="240"
          height="239"
        />
      </div>
      <div
        className="dW_i-definition dW_generic__animOnScroll dW_generic__toAnimOnScroll dW_generic__prepareAnimOnScroll dW_generic__startAnimOnScroll"
        data-paramsOnScroll="{}"
      >
        <h2>
          <span className="dW_id-content">
            <span>O QUE É A </span>
          </span>
          <br />
          <span className="dW_id-content">
            <span>ECO-CONCEPÇÃO</span>
          </span>
        </h2>
        <p>
          A eco-concepção consiste em integrar o meio ambiente desde a concepção
          de um produto, levando em consideração todo o seu ciclo de vida.
        </p>
        <p>
          <strong>Integrar o meio ambiente</strong> significa
          <strong>minimizar o impacto ambiental.</strong>
        </p>
        <p>
          Um produto pensado (ou repensado) com uma abordagem de eco-concepção
          continua sendo um produto que cumpre a mesma função de uso,
          apresentando, além disso, uma vantagem ambiental.
        </p>
      </div>
      <div
        className="dW_i-cycle-vie dW_generic__animOnScroll dW_generic__toAnimOnScroll dW_generic__prepareAnimOnScroll dW_generic__startAnimOnScroll"
        data-paramsOnScroll="{}"
      >
        <div className="dW_icv-schema">
          <div className="dW_icvs-picto" data-cle="global" id="icon" />
          <HoverComponent
            content={`<h4>Matérias primas</h4>
            <p>Extração e tratamento</p>`}
            dataCle="matieres"
            dataCleNext="production"
          />
          <HoverComponent
            content={`<h4>Produção</h4>
            <p>Técnicas de fabricação</p>`}
            dataCle="production"
            dataCleNext="transport"
          />
          <HoverComponent
            content={`<h4>Transporte</h4>
            <p>Do local de produção ao local de distribuição</p>`}
            dataCle="transport"
            dataCleNext="distribution"
          />
          <HoverComponent
            content={`<h4>Distribuição</h4>
            <p>Local e método de venda</p>`}
            dataCle="distribution"
            dataCleNext="utilisation"
          />
          <HoverComponent
            content={`<h4>Utilização</h4>
            <p>Uso, lavagem e manutenção</p>`}
            dataCle="utilisation"
            dataCleNext="fin-vie"
          />
          <HoverComponent
            content={`<h4>Fim da vida</h4>
            <p>Reparação, reciclagem, destruição</p>`}
            dataCle="fin-vie"
            dataCleNext="matieres"
          />
          <div
            className="dW_icvs-pointilles"
            data-cle="matieres-production"
            id="pointilles"
          />
          <div
            className="dW_icvs-pointilles"
            data-cle="production-transport"
            id="pointilles"
          />
          <div
            className="dW_icvs-pointilles"
            data-cle="transport-distribution"
            id="pointilles"
          />
          <div
            className="dW_icvs-pointilles"
            data-cle="distribution-utilisation"
            id="pointilles"
          />
          <div
            className="dW_icvs-pointilles"
            data-cle="utilisation-fin-vie"
            id="pointilles"
          />
        </div>
        <h3>
          <span className="dW_icv-content">
            <span>O CICLO DE VIDA DE UM PRODUTO</span>
          </span>
        </h3>
      </div>
    </div>
  )
}

export default Intro
