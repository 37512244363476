const FooterProdutosEco = () => {
  return (
    <div
      className="dW_footer dW_generic__animOnScroll dW_generic__toAnimOnScroll dW_generic__prepareAnimOnScroll dW_generic__startAnimOnScroll"
      data-paramsOnScroll="{}"
    >
      <picture>
        <source
          srcSet="https://decathlonpro.vteximg.com.br/arquivos/lp-ec-2020-immersive-footer.jpg"
          type="image/webp"
        />
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/lp-ec-2020-immersive-footer.jpg"
          width="1600"
          height="1068"
          alt=""
          title=""
        />
      </picture>
    </div>
  )
}

export default FooterProdutosEco
