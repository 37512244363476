import { useEffect, useState } from 'react'

import FooterProdutosEco from './FooterProdutosEco'
import ImpactoAmbiental from './ImpactoAmbiental'
import Intro from './Intro'
import SectionDetails from './SectionDetails'
import SectionEcoConcepcao from './SectionEcoConcepcao'
import SectionVideo from './SectionVideo'
import SectionYoga from './SectionYoga'
import './styles.scss'

const EcoSacolas = () => {
  const [scrollDown, setScrollDown] = useState(false)

  useEffect(() => {
    scrollDown && setScrollDown(false)
  }, [scrollDown])

  return (
    <>
      <div id="devWaiona" className="devWaiona-fr_FR">
        <SectionEcoConcepcao
          scrollToSection={() => {
            setScrollDown(true)
          }}
        />
        <Intro />
        <SectionVideo handleScrollDown={scrollDown} />
        <ImpactoAmbiental />
        <SectionYoga />
        <SectionDetails />
        <FooterProdutosEco />
      </div>
    </>
  )
}

export default EcoSacolas
