const ImpactoAmbiental = () => {
  return (
    <div
      className="dW_affichage dW_generic__animOnScroll dW_generic__toAnimOnScroll dW_generic__prepareAnimOnScroll dW_generic__startAnimOnScroll"
      data-paramsOnScroll="{}"
    >
      <div
        className="dW_a-logo dW_generic__eventOnScroll"
        data-paramsOnScroll="{}"
      >
        <div className="dW_al-content" id="letters-carousel">
          <div className="dW_alc-lettre" data-lettre="A" id="letters" />
          <div className="dW_alc-lettre" data-lettre="B" id="letters" />
          <div className="dW_alc-lettre" data-lettre="C" id="letters" />
          <div className="dW_alc-lettre" data-lettre="D" id="letters" />
          <div className="dW_alc-lettre" data-lettre="E" id="letters" />
        </div>
      </div>
      <div className="dW_a-texte">
        <h2>
          <span className="dW_at-content">
            <span>IMPACTO AMBIENTAL</span>
          </span>
        </h2>
        <p>
          <strong>Qual é a relação com a eco-concepção?</strong>
        </p>
        <p>
          Para ajudá-los a fazer uma escolha responsável, além da abordagem da
          eco-concepção, iniciamos uma avaliação do impacto ambiental de alguns
          de nossos produtos.
        </p>
        <p>
          A classificação ambiental dá a chance de comparar o impacto ambiental
          de vários produtos de uma mesma categoria (por exemplo, na categoria
          de produtos de camisetas com mangas curtas).
        </p>
        <p>
          Hoje é apresentada uma nota que vai de A a E nos produtos avaliados.
          Esse sistema de classificação, já presente nos produtos
          eletrodomésticos, começa a ser implantado em alguns de nossos
          produtos, graças aos cálculos de nossos engenheiros, para nos oferecer
          uma comparação de seus impactos. Essa avaliação é feita sobre o ciclo
          de vida do produto.
        </p>
      </div>
    </div>
  )
}

export default ImpactoAmbiental
