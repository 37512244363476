interface ILoginFormProps {
  scrollToSection: (ev: any) => void
}

const SectionEcoConcepcao = ({ scrollToSection }: ILoginFormProps) => {
  return (
    <div
      className="dW_cover dW_generic__animOnScroll dW_generic__eventOnScroll"
      data-paramsOnScroll="{}"
    >
      <div className="dW_c-titre" data-parallax-ratio="0.525">
        <h1>
          <span className="dW_ct-content">A caminhada</span>
          <br />
          <span className="dW_ct-content">
            <span>da</span>
            <span>ECO</span>
            <span>-CONCEPÇÃO</span>
          </span>
          <br />
          <span className="dW_ct-content">
            na <span>Decathlon</span>
          </span>
        </h1>
        <p>
          Além de oferecer produtos esportivos de alta performance, as equipes
          da Decathlon se dedicam a desenvolvê-lo respeitando o meio ambiente.
          Para isso, utilizamos o conceito de eco-concepção.
        </p>
      </div>
      <div className="dW_c-background">
        <div
          className="dW_cb-visuel"
          data-cle="trees"
          data-parallax-ratio="0.6"
          id="trees"
        />
        <div
          className="dW_cb-visuel"
          data-cle="landscape"
          data-parallax-ratio="0.45"
          id="landscape"
        />
        <div
          className="dW_cb-visuel"
          data-cle="mountains"
          data-parallax-ratio="0.25"
          id="mountains"
        />
        <div
          className="dW_cb-visuel"
          data-cle="sky"
          data-parallax-ratio="0.15"
          id="sky"
        />
        <div className="dW_cb-gabarit">
          <picture>
            <source
              srcSet="
              https://decathlonpro.vteximg.com.br/arquivos/lp-ec-2020-cover-mask.png
            "
              type="image/webp"
            />
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/lp-ec-2020-cover-mask.png"
              width="1600"
              height="900"
              alt=""
              title=""
            />
          </picture>
        </div>
      </div>
      <button
        className="dW_c-scroll-down dW_generic__hover dW_generic__out"
        data-parallax-ratio="0.125"
        id="arrow-down-intro"
        onClick={scrollToSection}
      >
        <p>
          <a title="Lire la suite" href="#f">
            <span />
          </a>
        </p>
      </button>
    </div>
  )
}

export default SectionEcoConcepcao
