const SectionYoga = () => {
  return (
    <div
      className="dW_ambiance-yoga dW_generic__eventOnScroll dW_generic__preloadOnScroll"
      data-paramsOnScroll="{'preload':{'type':'bg','url':'/arquivos/lp-ec-2020-ambiance-yoga-picture.jpg','urlWebp':'/arquivos/lp-ec-2020-ambiance-yoga-picture.jpg'}}"
      data-parallax-ratio="1.25"
      id="gabarit"
    >
      <div className="dW_ay-gabarit">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/lp-ec-2020-ambiance-yoga-picture.jpg"
          width="1600"
          height="800"
          alt=""
          title=""
        />
      </div>
    </div>
  )
}

export default SectionYoga
