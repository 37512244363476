import { useState } from 'react'

type HoverComponentProps = {
  content: string
  dataCle: string
  dataCleNext: string
}
const HoverComponent = ({
  content,
  dataCle,
  dataCleNext,
}: HoverComponentProps) => {
  const [show, setShow] = useState('close')

  return (
    <div className="App">
      <button
        className={`dW_icvs-picto dW_generic__hover dW_generic__${show}`}
        data-cle={dataCle}
        id="icon"
        data-cle-next={dataCleNext}
        onMouseOver={() => setShow('open')}
        onMouseOut={() => setShow('close')}
        onFocus={() => setShow('open')}
        onBlur={() => setShow('close')}
      >
        <div className="dW_icvsp-popover">
          <div
            className="dW_icvspp-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </button>
    </div>
  )
}

export default HoverComponent
